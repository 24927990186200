import React from 'react'
import { MdNavigateNext } from 'react-icons/md'
import { AppStoreDownload, PlayStoreDownload } from '../utils/nav'
import { FaApple, FaAndroid } from 'react-icons/fa'
import { ImAndroid } from 'react-icons/im'
import styled from 'styled-components'
import { Colors, MediaQuery } from '../styles'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

export type ColorScheme = 'blue' | 'white'

interface Props {
  type: 'ios' | 'android'
  colorScheme?: ColorScheme
}

const Button = styled<any>(OutboundLink)`
  display: inline-flex;
  font-family: arial;
  font-weight: bold;
  background: ${(p) =>
    p.colorScheme === 'white' ? Colors.white : Colors.blue};
  color: ${(p) => (p.colorScheme === 'white' ? Colors.blue : Colors.white)};
  /* height: 50px; */
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  width: 250px;
`

const Wrap = styled.div`
  display: flex;
  padding: 8px 15px;
`
const IconWrap = styled.div``

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 0 10px;
`

const Line1 = styled.span`
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 120%;
  text-align: left;
`

const Line2 = styled.span`
  font-size: 1.8rem;
  line-height: 100%;
  text-align: left;
`

const ios = {
  line1: 'Download on the',
  line2: 'App Store',
  link: AppStoreDownload.link
}

const android = {
  line1: 'Get it on',
  line2: 'Play Store',
  link: PlayStoreDownload.link
}

const ButtonDownloadApp: React.FC<Props> = ({ type, colorScheme }) => {
  const value = type === 'ios' ? ios : android
  const scheme = colorScheme ?? 'blue'

  const iconColor = scheme === 'blue' ? '#fff' : Colors.blue

  const Icon =
    type === 'ios' ? (
      <FaApple size={50} color={iconColor} />
    ) : (
      <ImAndroid size={50} color={iconColor} />
    )

  return (
    <Button data-type={type} href={value.link} colorScheme={colorScheme}>
      <Wrap>
        <IconWrap>{Icon}</IconWrap>
        <TextWrap>
          <Line1>{value.line1}</Line1>
          <Line2>{value.line2}</Line2>
        </TextWrap>
      </Wrap>
    </Button>
  )
}

export default ButtonDownloadApp
