import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import HowItWorksMainCard from '../components/HowItWorksMainCard'
import styled from 'styled-components'
import AppStoreIcon from '../components/AppStoreIcon'
import { Colors, H1, H2, MediaQuery, Text } from '../styles'
import ButtonDownloadApp from '../components/ButtonDownloadApp'
import { AppStoreDownload, PlayStoreDownload } from '../utils/nav'
import DownloadButtons from '../components/DownloadButtons'
import ButtonFreeTrial from '../components/ButtonFreeTrial'

const Wrap = styled.div`
  padding: 50px 0 0 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 50px auto 0 auto; */
  max-width: 1200px;
`

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  padding: 40px 0;
  /* background: green; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  @media ${MediaQuery.Medium} {
    flex-direction: row;
    justify-content: space-evenly;
  }
  /* margin: 10px 0 0 0; */
`

const AppleBaseSize = 317 / 1
const AndroidBaseSize = 300 / 1

const DeviceImage = styled.div`
  width: 80%;
  /* height: 600px; */
`

const HeaderTitle = styled(H2)`
  font-size: 1.5rem;
  padding: 0 25px;
  text-transform: none;
  text-align: center;
  margin: 40px 0 0 0;
  @media ${MediaQuery.Medium} {
    font-size: 2rem;
  }
`

const SubText = styled(Text)`
  text-align: center;
  padding: 20px 0 0 0;
`

const DownloadPage = () => (
  <Layout>
    <Seo title="Download App" url="https://myschoolfiles.com/download" />
    <div style={{}}>
      <Wrap>
        <DeviceImage>
          <StaticImage
            style={{ width: '100%' }}
            src="../../static/images/download/devices.png"
            alt="Install My School Files for Android"
          />
        </DeviceImage>
        <HeaderTitle>
          Install now and enjoy a<br /> 30 DAY FREE Trial.
        </HeaderTitle>
        <SubText>Thanks for downloading My School Files.</SubText>

        <ButtonWrap>
          <div style={{ padding: 20 }}>
            <ButtonDownloadApp type="ios" />
          </div>
          <ButtonDownloadApp type="android" />
        </ButtonWrap>
      </Wrap>
    </div>
  </Layout>
)

export default DownloadPage
